import React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/blog/seo';
import UserDetailsForm from '../components/layout/UserDetailsPage/user-details-form';
import Layout from '../containers/layout';
import 'bootstrap/dist/css/bootstrap.min.css';

export const query = graphql`
  query userDetailsPage {
    pageLabels_home: sanityPage(pageName: { eq: "Home" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    pageLabels_userDetails: sanityPage(pageName: { eq: "UsersDetailsPage" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;
const UserDetailsPage = (props: any) => {
  const { data, errors } = props;
  const site = (data || {}).site;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  // const postNodes = data && data.posts && mapEdgesToNodes(data.posts)
  const labels = [...data.pageLabels_home.labels, ...data.pageLabels_userDetails.labels];
  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <UserDetailsForm labelArr={labels} />
      </Container>
    </Layout>
  );
};
export default UserDetailsPage;
