import React, { useState, Component } from 'react';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import { Container, Button, Form, Row, Col, Modal, Spinner } from 'react-bootstrap';
import styles from './user-details-form.style';
import * as firebase from '../../../lib/firebase';
import { navigate } from 'gatsby';
import { RootState } from '../../../redux/store/index';
import { Dispatch } from 'redux';
import { ConnectedProps, connect } from 'react-redux';
import { SystemActionTypes } from '../../../redux/store/system/types';
import axios from 'axios';
import clientConfig from '../../../../client-config';
import styles_module from './styles.module.css';
import { ErrorMessage } from 'react-hook-form';
import hebrewTexts from '../commonHebrewTexts';
interface DispatchProps {
  isLoggedIn: () => void;
}

interface IncommingProps {
  labelArr: string[];
}
interface IState {
  show: boolean;
  errorMessage: Array<string>;
  firstName: string;
  lastName: string;
  idNumber: number;
  phoneNumber: string;
  userEmail: string;
  userPassword: string;
  city: string;
  street: string;
  houseNumber: number;
  areYouSoleOwner: string;
  advisorWIYRB: string;
  whatIsYourPosition: string;
  workerOtherInput: boolean;
  advisorOtherInput: boolean;
  areYouCPA: string;
  writeYearlyReport: string;
  agree: boolean;
  ownerSection: boolean;
  advisorSection: boolean;
  workerSection: boolean;
  loading: boolean;
}

const NETWORK_ERROR = 'Network Error';
const EMAIL_ALREADY_EXIST = 'The email address is already in use by another account.';

const mapState = (state: RootState) => ({
  username: state.system.userName,
  loggedIn: state.system.loggedIn,
  companyName: state.system.companyName,
  currentPage: state.system.currentPage,
  idNumber: state.system.idNumber,
  businessId: state.system.businessId,
});

const mapDispatch = (dispatch: Dispatch<SystemActionTypes>) => ({
  updateSession: (
    loggedIn: boolean,
    userName: string,
    currentPage: string,
    companyName: string,
    idNumber: number,
    businessId: number
  ) => {
    dispatch({
      type: 'UPDATE_SESSION',
      payload: { loggedIn, userName, currentPage, companyName, idNumber, businessId },
    });
  },
});

const connector = connect(mapState, mapDispatch);

type Props = IncommingProps & ConnectedProps<typeof connector>;

const HOUSE_NUMBER_DEFAULT_MIN: number = 1;

class UserDetailsForm extends React.Component<Props, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      errorMessage: [],
      firstName: '',
      lastName: '',
      idNumber: 0,
      phoneNumber: '',
      userEmail: '',
      userPassword: '',
      city: '',
      street: '',
      houseNumber: HOUSE_NUMBER_DEFAULT_MIN,
      areYouSoleOwner: '',
      advisorWIYRB: '',
      whatIsYourPosition: '',
      workerOtherInput: false,
      advisorOtherInput: false,
      areYouCPA: '',
      writeYearlyReport: '',
      agree: false,
      ownerSection: true,
      advisorSection: false,
      workerSection: false,
      loading: false,
    };
  }

  handleClose = () => {
    this.updateState({ show: false });
  };

  handleShow = () => {
    this.updateState({ show: true });
  };

  handleChange(setValueTo: any, e: any) {
    if (e.target.value > 0) {
      setValueTo(e.target.value);
    }
  }

  updateState(stateVal: any) {
    this.setState(stateVal);
  }

  validateMail(email: string) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  }

  isValidIsraeliID(id: string) {
    var id = String(id).trim();
    if (id.length > 9 || id.length < 5 || isNaN(parseInt(id))) return false;

    // Pad string with zeros up to 9 digits
    id = id.length < 9 ? ('00000000' + id).slice(-9) : id;

    return (
      Array.from(id, Number).reduce((counter, digit, i) => {
        const step = digit * ((i % 2) + 1);
        return counter + (step > 9 ? step - 9 : step);
      }) %
        10 ===
      0
    );
  }

  submitHandler = async () => {
    let errorMessage = [];

    if (!this.state.firstName) {
      errorMessage.push(t(this.props.labelArr, 'firstName_Label', 'heb'));
    }
    if (!this.state.lastName) {
      errorMessage.push(t(this.props.labelArr, 'lastName_Label', 'heb'));
    }
    if (this.state.idNumber == 0 || !this.isValidIsraeliID(this.state.idNumber.toString())) {
      errorMessage.push(t(this.props.labelArr, 'idNumber_Label', 'heb'));
    }
    let phoneRegex =
      /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;
    if (!this.state.phoneNumber.match(phoneRegex)) {
      errorMessage.push(t(this.props.labelArr, 'phoneNumber_Label', 'heb'));
    }

    if (!this.validateMail(this.state.userEmail)) {
      errorMessage.push(t(this.props.labelArr, 'email_Label', 'heb'));
    }
    if (!this.state.userPassword) {
      errorMessage.push(t(this.props.labelArr, 'password_Label', 'heb'));
    }

    if (!this.state.city) {
      errorMessage.push(t(this.props.labelArr, 'city_Label', 'heb'));
    }
    if (!this.state.street) {
      errorMessage.push(t(this.props.labelArr, 'street_Label', 'heb'));
    }

    if (this.state.houseNumber < HOUSE_NUMBER_DEFAULT_MIN || !this.state.houseNumber) {
      errorMessage.push(t(this.props.labelArr, 'houseNumber_Label', 'heb'));
    }

    if (this.state.ownerSection) {
      if (!this.state.areYouSoleOwner) {
        errorMessage.push(t(this.props.labelArr, 'areYouSoleOwner_Label', 'heb'));
      }
    }
    if (this.state.workerSection) {
      if (this.state.whatIsYourPosition === '' || this.state.whatIsYourPosition === 'other') {
        errorMessage.push(t(this.props.labelArr, 'whatIsYourPosition_Label', 'heb'));
      }
    }
    if (this.state.advisorSection) {
      if (this.state.advisorWIYRB === '' || this.state.advisorWIYRB === 'other') {
        errorMessage.push(t(this.props.labelArr, 'advisor_SectionTitle', 'heb'));
      }
      if (!this.state.areYouCPA) {
        errorMessage.push(t(this.props.labelArr, 'areYouCPA_Label', 'heb'));
      }
      if (!this.state.writeYearlyReport) {
        errorMessage.push(t(this.props.labelArr, 'areYouWriteTheYearlyReport_Label', 'heb'));
      }
    }
    if (!this.state.agree) {
      errorMessage.push(t(this.props.labelArr, 'agree_Label', 'heb'));
    }

    if (errorMessage.length > 0) {
      this.setState({ errorMessage: errorMessage });
      this.handleShow();
    } else {
      this.setState({ loading: true });
      let userDetails = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        idNumber: this.state.idNumber,
        phoneNumber: this.state.phoneNumber,
        userEmail: this.state.userEmail,
        userPassword: this.state.userPassword,
        city: this.state.city,
        street: this.state.street,
        houseNumber: this.state.houseNumber,
        areYouSoleOwner: this.state.areYouSoleOwner,
        whatIsYourPosition: this.state.whatIsYourPosition,
        advisorWIYRB: this.state.advisorWIYRB,
        areYouCPA: this.state.areYouCPA,
        writeYearlyReport: this.state.writeYearlyReport,
      };
      let user = {
        email: this.state.userEmail,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.userPassword,
        idNumber: this.state.idNumber,
        phoneNumber: this.state.phoneNumber,
        city: this.state.city,
        street: this.state.street,
        houseNumber: this.state.houseNumber,
        isCPA: this.state.areYouCPA,
        currentPage: '/BusinessDetailsPage',
        uid: '',
      };
      let businessUserRelationPosition = {
        position: '',
        otherDetails: '',
        areYouCPA: '',
        writeYearlyReport: '',
      };
      if (this.state.ownerSection) {
        businessUserRelationPosition.position = '';
        businessUserRelationPosition.otherDetails = '';
      } else if (this.state.workerSection) {
        businessUserRelationPosition.position = this.state.whatIsYourPosition;
        businessUserRelationPosition.otherDetails = '';
      } else if (this.state.advisorSection) {
        businessUserRelationPosition.position = this.state.whatIsYourPosition;
        businessUserRelationPosition.otherDetails = '';
        (businessUserRelationPosition.areYouCPA = this.state.areYouCPA),
          (businessUserRelationPosition.writeYearlyReport = this.state.writeYearlyReport);
      }
      // try
      let loanRequest = JSON.parse(localStorage.loanDetails);
      // catch
      let business = {
        companyName: loanRequest.companyName,
        registeredCompanyId: loanRequest.registeredCompanyId,
      };
      let businessUserRelation = {
        isOwner: this.state.ownerSection,
        isWorker: this.state.workerSection,
        isAdvisor: this.state.advisorSection,
        areYouSoleOwner: this.state.areYouSoleOwner,
      };
      let request: any = {
        user: user,
        loanRequest: loanRequest,
        business: business,
        businessUserRelation: businessUserRelation,
      };
      if (this.state.workerSection || this.state.advisorSection) {
        request.businessUserRelationPosition = businessUserRelationPosition;
      }

      let userName = this.state.firstName + ' ' + this.state.lastName;

      try {
        const registeredUser = await firebase.register(userDetails.userEmail, userDetails.userPassword);
        //alert("344 " + JSON.stringify(registeredUser));

        if (registeredUser?.additionalUserInfo?.isNewUser) {
          registeredUser?.user?.updateProfile({ displayName: userName });

          let authUser = firebase.getCurrentUser(); //await firebase.signIn(userDetails.userEmail, userDetails.userPassword);

          //alert("012" + JSON.stringify(authUser));
          request ? (request.user['uid'] = authUser?.uid) : '';
          try {
            let res = await axios.post(`${clientConfig.backend}/api/v1/UserDetailsPage`, request);

            //alert("res " + res.data.businessId);
            localStorage.setItem(
              'userDetails',
              JSON.stringify({
                userName: userName,
                loggedIn: true,
                currentPage: '/BusinessDetailsPage',
                uid: authUser?.uid,
                idNumber: this.state.idNumber,
                businessId: res.data.businessId,
              })
            );
            this.props.updateSession(
              true,
              userName,
              user.currentPage,
              business.companyName ? business.companyName : '',
              user.idNumber,
              res.data.businessId
            );
            navigate('/BusinessDetailsPage');
          } catch (error) {
            let customErrorMessage = [error.message];
            if (error.message === NETWORK_ERROR) {
              customErrorMessage = [hebrewTexts.networkErrorMessage];
            }

            authUser?.delete();
            this.setState({ loading: false });
            this.setState({ errorMessage: customErrorMessage });
            this.handleShow();
          }
        } else {
          errorMessage.push(registeredUser);
          this.setState({ loading: false });
          this.setState({ errorMessage: errorMessage });
          this.handleShow();
        }
      } catch (error) {
        let customErrorMessage = [error.message];
        if (error.message === EMAIL_ALREADY_EXIST) {
          customErrorMessage = [hebrewTexts.userExistErrorMessage];
        }

        this.setState({ loading: false });
        this.setState({ errorMessage: customErrorMessage });
        this.handleShow();
      }
    }
  };

  render() {
    const {
      show,
      errorMessage,
      firstName,
      lastName,
      idNumber,
      phoneNumber,
      userEmail,
      userPassword,
      city,
      street,
      houseNumber,
      areYouSoleOwner,
      advisorWIYRB,
      whatIsYourPosition,
      workerOtherInput,
      advisorOtherInput,
      areYouCPA,
      writeYearlyReport,
      agree,
      ownerSection,
      advisorSection,
      workerSection,
    } = this.state;
    const { labelArr } = this.props;

    return (
      <Container as={Row} style={styles.loanContainer}>
        <Modal show={show} onHide={this.handleClose}>
          <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
            <h6>{t(labelArr, 'FollowingRequiredFields', 'heb')} </h6>
            {errorMessage.map((value) => {
              return (
                <div className='text-right' key={value}>
                  {value}
                </div>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              {hebrewTexts.close}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className='text-center'>
          <h1>{t(labelArr, 'userDetailsPage_Title', 'heb')}</h1>
          <p>{t(labelArr, 'userDetailsPage_SubTitle', 'heb')}</p>
        </div>
        <Row className='justify-content-sm-center'>
          <Col sm={12}>
            <div>
              <Form.Group as={Row}>
                <Col sm={4}>
                  <Form.Label>{t(labelArr, 'myRelationToBusiness_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm={2} style={{ display: 'flex' }}>
                  <Form.Label>&nbsp;&nbsp;{t(labelArr, 'owner_Button', 'heb')}</Form.Label>
                  <Form.Check
                    onChange={() => {
                      this.updateState({
                        ownerSection: true,
                        workerSection: false,
                        advisorSection: false,
                      });
                    }}
                    type='radio'
                    defaultChecked
                    name='selectSectionRadios'
                  />
                </Col>
                <Col sm={2} style={{ display: 'flex' }}>
                  <Form.Label>{t(labelArr, 'worker_Button', 'heb')}</Form.Label>
                  <Form.Check
                    onChange={() => {
                      this.updateState({
                        workerSection: true,
                        ownerSection: false,
                        advisorSection: false,
                      });
                    }}
                    type='radio'
                    name='selectSectionRadios'
                  />
                </Col>
                <Col sm={2} style={{ display: 'flex' }}>
                  <Form.Label>{t(labelArr, 'advisor_Button', 'heb')}</Form.Label>
                  <Form.Check
                    onChange={() => {
                      this.updateState({
                        advisorSection: true,
                        workerSection: false,
                        ownerSection: false,
                      });
                    }}
                    type='radio'
                    name='selectSectionRadios'
                  />
                </Col>
              </Form.Group>
            </div>
          </Col>
        </Row>
        <Row className='justify-content-md-center' style={{ paddingTop: '2%' }}>
          <Col md={9} className='w-40'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>
                {t(labelArr, 'userPrivateDetails_SectionTitle', 'heb')}
              </legend>
              <Row>
                <Col sm='3'>
                  <Form.Label className='text-right'>{t(labelArr, 'firstName_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='3' style={{ display: 'flex', marginBottom: '1rem' }}>
                  <Form.Control
                    onChange={(e) => {
                      this.updateState({ firstName: e.target.value });
                    }}
                    value={firstName ? firstName : ''}
                    type='text'
                  />
                </Col>
                <Col sm='2' style={{ padding: 0, marginLeft: -15, marginRight: 15 }}>
                  <Form.Label style={{ width: '100%' }}>{t(labelArr, 'lastName_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='3'>
                  <Form.Control
                    onChange={(e) => {
                      this.updateState({ lastName: e.target.value });
                    }}
                    value={lastName ? lastName : ''}
                    type='text'
                  />
                </Col>
              </Row>
              <Row>
                <Col sm='3'>
                  <Form.Label>{t(labelArr, 'idNumber_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='8'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.updateState({ idNumber: e.target.value });
                      }}
                      value={idNumber ? idNumber : ''}
                      type='text'
                      pattern='[0-9]*'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a valid id number.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm='3'>
                  <Form.Label>{t(labelArr, 'phoneNumber_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='8'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.updateState({ phoneNumber: e.target.value });
                      }}
                      value={phoneNumber ? phoneNumber : ''}
                      type='text'
                      pattern='[0-9]*'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a valid phone number.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col md={9} className='w-40'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'signUpDetails_SectionTitle', 'heb')}</legend>
              <Row>
                <Col sm='4'>
                  <Form.Label>{t(labelArr, 'email_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='7'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.updateState({ userEmail: e.target.value });
                      }}
                      type='email'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a valid id number.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm='4'>
                  <Form.Label>{t(labelArr, 'password_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='7'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.updateState({ userPassword: e.target.value });
                      }}
                      type='password'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a valid phone number.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col md={9} className='w-40'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'addressDetails_SectionTitle', 'heb')}</legend>
              <Row>
                <Col sm='2'>
                  <Form.Label>{t(labelArr, 'city_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='9'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.updateState({ city: e.target.value });
                      }}
                      type='text'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a city name.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm='2'>
                  <Form.Label>{t(labelArr, 'street_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='4'>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.updateState({ street: e.target.value });
                      }}
                      type='text'
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a street name.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm='5' style={{ display: 'flex' }}>
                  <Form.Label style={{ width: '50%', marginLeft: '5%' }}>
                    {t(labelArr, 'houseNumber_Label', 'heb')}
                  </Form.Label>
                  <Form.Group>
                    <Form.Control
                      onChange={(e) => {
                        this.setState({ houseNumber: parseInt(e.target.value) });
                      }}
                      type='number'
                      defaultValue={HOUSE_NUMBER_DEFAULT_MIN.toString()}
                      min={HOUSE_NUMBER_DEFAULT_MIN.toString()}
                      required
                    />
                    <Form.Control.Feedback type='invalid'>Please provide a house number.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
        <Row
          style={{ display: ownerSection ? '' : 'none', paddingTop: '2%', height: '8.5rem' }}
          className='justify-content-md-center'
        >
          <Col md={9} className='w-38'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'owner_SectionTitle', 'heb')}</legend>
              <Row>
                <Col sm='5'>
                  <Form.Label className='text-right'>{t(labelArr, 'areYouSoleOwner_Label', 'heb')}</Form.Label>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group style={{ display: 'flex' }}>
                        <Form.Label>{t(labelArr, 'yes_RadioButton', 'heb')}</Form.Label>
                        <Form.Check
                          onChange={() => {
                            this.updateState({ areYouSoleOwner: 'yes' });
                          }}
                          type='radio'
                          name='areYouSoleOwnerRadios'
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group style={{ display: 'flex' }}>
                        <Form.Label className='mr-3'>{t(labelArr, 'no_RadioButton', 'heb')}</Form.Label>
                        <Form.Check
                          onChange={() => {
                            this.updateState({ areYouSoleOwner: 'no' });
                          }}
                          type='radio'
                          name='areYouSoleOwnerRadios'
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
        <Row
          style={{ display: workerSection ? '' : 'none', paddingTop: '2%', height: '9.5rem' }}
          className='justify-content-md-center'
        >
          <Col md={9} className='w-40'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'worker_SectionTitle', 'heb')}</legend>
              <Form.Group as={Row}>
                <Form.Label column sm='4'>
                  {t(labelArr, 'whatIsYourRelationToBusiness_Label', 'heb')}
                </Form.Label>
                <Col sm='8'>
                  <Form.Control
                    className='text-right mb-2'
                    as='select'
                    onChange={(e) => {
                      let show = e.target.value === 'other' ? true : false;
                      this.updateState({ workerOtherInput: show });
                      this.updateState({ whatIsYourPosition: e.target.value });
                    }}
                  >
                    <option value=''>----בחר----</option>
                    <option value={t(labelArr, 'po_CEO', 'heb')}>{t(labelArr, 'po_CEO', 'heb')}</option>
                    <option value={t(labelArr, 'po_CFO', 'heb')}>{t(labelArr, 'po_CFO', 'heb')}</option>
                    <option value={t(labelArr, 'po_COO', 'heb')}>{t(labelArr, 'po_COO', 'heb')}</option>
                    <option value={t(labelArr, 'po_bookkeeping', 'heb')}>{t(labelArr, 'po_bookkeeping', 'heb')}</option>
                    <option value={t(labelArr, 'po_secretary', 'heb')}>{t(labelArr, 'po_secretary', 'heb')}</option>
                    <option value='other'>{t(labelArr, 'po_other', 'heb')}</option>
                  </Form.Control>
                  {workerOtherInput ? (
                    <Form.Control
                      type='text'
                      onChange={(e) => {
                        this.updateState({ whatIsYourPosition: e.target.value });
                      }}
                      style={{ marginTop: '5%' }}
                    />
                  ) : null}
                </Col>
              </Form.Group>
            </fieldset>
          </Col>
        </Row>
        <Row
          style={{ display: advisorSection ? '' : 'none', paddingTop: '2%' }}
          className={['justify-content-md-center', styles_module.advisorSection].join(' ')}
        >
          <Col md={9} className='w-40'>
            <fieldset style={styles.fieldsetSchedulerBorder}>
              <legend style={styles.legendSchedulerBorder}>{t(labelArr, 'advisor_SectionTitle', 'heb')}</legend>
              <Form.Group as={Row}>
                <Form.Label column sm='6'>
                  {t(labelArr, 'whatIsYourRelationToBusiness_Label', 'heb')}
                </Form.Label>
                <Col sm='4'>
                  <Form.Control
                    className='text-right'
                    as='select'
                    onChange={(e) => {
                      let show = e.target.value === 'other' ? true : false;
                      this.updateState({ advisorOtherInput: show });
                      this.updateState({ advisorWIYRB: e.target.value });
                    }}
                  >
                    <option value=''></option>
                    <option value={t(labelArr, 'bro_CPA', 'heb')}>{t(labelArr, 'bro_CPA', 'heb')}</option>
                    <option value={t(labelArr, 'bro_financeAdvisor', 'heb')}>
                      {t(labelArr, 'bro_financeAdvisor', 'heb')}
                    </option>
                    <option value='other'>{t(labelArr, 'bro_other', 'heb')}</option>
                  </Form.Control>
                  {advisorOtherInput ? (
                    <Form.Control
                      type='text'
                      onChange={(e) => {
                        this.updateState({ advisorWIYRB: e.target.value });
                      }}
                    />
                  ) : null}
                </Col>
                {/* <Col sm="4">
                  {advisorOtherInput ? (
                    <Form.Control
                      type="text"
                      onChange={e => {
                        this.updateState({ advisorWIYRB: e.target.value });
                      }}
                    />
                  ) : null}
                </Col> */}
              </Form.Group>
              <Row>
                <Col sm='6'>
                  <Form.Label>{t(labelArr, 'areYouCPA_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='6'>
                  <Form.Group style={{ display: 'flex' }}>
                    <Form.Label>{t(labelArr, 'yes_RadioButton', 'heb')}</Form.Label>
                    <Form.Check
                      onChange={() => {
                        this.updateState({ areYouCPA: 'Yes' });
                      }}
                      type='radio'
                      name='areYouCPARadios'
                    />
                    <Form.Label className='mr-3'>{t(labelArr, 'no_RadioButton', 'heb')}</Form.Label>
                    <Form.Check
                      onChange={() => {
                        this.updateState({ areYouCPA: 'No' });
                      }}
                      type='radio'
                      name='areYouCPARadios'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Form.Label>{t(labelArr, 'areYouWriteTheYearlyReport_Label', 'heb')}</Form.Label>
                </Col>
                <Col sm='6'>
                  <Form.Group style={{ display: 'flex' }}>
                    <Form.Label>{t(labelArr, 'yes_RadioButton', 'heb')}</Form.Label>
                    <Form.Check
                      onChange={() => {
                        this.updateState({ writeYearlyReport: 'Yes' });
                      }}
                      type='radio'
                      name='writeYearlyReportRadios'
                    />
                    <Form.Label className='mr-3'>{t(labelArr, 'no_RadioButton', 'heb')}</Form.Label>
                    <Form.Check
                      onChange={() => {
                        this.updateState({ writeYearlyReport: 'No' });
                      }}
                      type='radio'
                      name='writeYearlyReportRadios'
                    />
                  </Form.Group>
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
        <Row className='justify-content-md-center text-right' style={{ paddingTop: '3%' }}>
          <Col md={9} className='w-40'>
            <div style={{ border: '2px solid #ccc', padding: '3%' }}>
              {t(labelArr, 'disclaimerPre', 'heb') +
                (firstName ? firstName + ' ' : '__') +
                (lastName ? lastName : '__') +
                t(labelArr, 'disclaimerMid', 'heb') +
                (idNumber ? idNumber : '______') +
                t(labelArr, 'disclaimerPost', 'heb')}
            </div>
          </Col>
        </Row>
        <Row className='justify-content-md-end'>
          <Col md={6} style={{ display: 'flex', padding: '2%' }}>
            <Form.Label style={styles.checkBox}>{t(labelArr, 'agree_Label', 'heb')}</Form.Label>
            <Form.Check
              // className="ml-3"
              style={styles.checkBox}
              onChange={(e: { target: { checked: any } }) => {
                this.updateState({ agree: e.target.checked });
              }}
              type='checkbox'
              name='formHorizontalRadios'
            />
            <Button
              onClick={this.submitHandler}
              disabled={!agree}
              variant='primary'
              type='submit'
              style={styles.btnNext}
            >
              {' '}
              {this.state.loading ? (
                <Spinner animation='border' />
              ) : (
                <>{t(labelArr, 'continue_Button', 'heb') + ' >'}</>
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connector(UserDetailsForm);
